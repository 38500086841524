var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"navbar navbar-expand-md navbar-dark mt-3"},[_c('div',{staticClass:"container"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"collapse navbar-collapse justify-content-end text-end text-lg-start mt-3 mt-lg-0",attrs:{"id":"navbarText"}},[_c('ul',{staticClass:"navbar-nav mb-2 mb-lg-0"},[_c('li',{staticClass:"nav-item"},[_c('a',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
              el: '#projects',
              container: 'body',
              offset: -50,
              duration: 100,
              easing: 'ease'
            }),expression:"{\n              el: '#projects',\n              container: 'body',\n              offset: -50,\n              duration: 100,\n              easing: 'ease'\n            }"}],staticClass:"nav-link",attrs:{"aria-current":"page","href":"#"}},[_vm._v(" Projekty ")])]),_c('li',{staticClass:"nav-item"},[_c('a',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
              el: '#tools',
              container: 'body',
              offset: -50,
              duration: 100,
              easing: 'ease'
            }),expression:"{\n              el: '#tools',\n              container: 'body',\n              offset: -50,\n              duration: 100,\n              easing: 'ease'\n            }"}],staticClass:"nav-link",attrs:{"href":"#"}},[_vm._v(" Narzędzia pracy ")])]),_c('li',{staticClass:"nav-item"},[_c('a',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
              el: 'footer',
              container: 'body',
              offset: -50,
              duration: 100,
              easing: 'ease'
            }),expression:"{\n              el: 'footer',\n              container: 'body',\n              offset: -50,\n              duration: 100,\n              easing: 'ease'\n            }"}],staticClass:"nav-link",attrs:{"href":"#"}},[_vm._v(" Kontakt ")])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"navbar-brand"},[_c('img',{attrs:{"src":require("../assets/images/email.svg"),"alt":"Email Icon"}}),_vm._v(" lukasz@sekutowicz.pl ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"navbar-toggler",attrs:{"type":"button","data-bs-toggle":"collapse","data-bs-target":"#navbarText","aria-controls":"navbarText","aria-expanded":"false","aria-label":"Toggle navigation"}},[_c('span',{staticClass:"navbar-toggler-icon"})])}]

export { render, staticRenderFns }