import Vue from "vue";
import App from "./App.vue";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min";
import "./assets/css/global.scss";
import "./assets/css/tooltips.scss";
import "animate.css/animate.min.css";
import "./assets/css/scrollbar.scss";

import { VTooltip } from "v-tooltip";
Vue.directive("tooltip", VTooltip);

import VueScrollTo from "vue-scrollto";
Vue.use(VueScrollTo);

Vue.config.productionTip = false;

new Vue({
  render: h => h(App)
}).$mount("#app");
