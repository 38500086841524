<template>
  <nav class="navbar navbar-expand-md navbar-dark mt-3">
    <div class="container">
      <div class="navbar-brand">
        <img src="../assets/images/email.svg" alt="Email Icon" />
        lukasz@sekutowicz.pl
      </div>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarText"
        aria-controls="navbarText"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div
        class="collapse navbar-collapse justify-content-end text-end text-lg-start mt-3 mt-lg-0"
        id="navbarText"
      >
        <ul class="navbar-nav mb-2 mb-lg-0">
          <li class="nav-item">
            <a
              class="nav-link"
              aria-current="page"
              href="#"
              v-scroll-to="{
                el: '#projects',
                container: 'body',
                offset: -50,
                duration: 100,
                easing: 'ease'
              }"
            >
              Projekty
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="#"
              v-scroll-to="{
                el: '#tools',
                container: 'body',
                offset: -50,
                duration: 100,
                easing: 'ease'
              }"
            >
              Narzędzia pracy
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="#"
              v-scroll-to="{
                el: 'footer',
                container: 'body',
                offset: -50,
                duration: 100,
                easing: 'ease'
              }"
            >
              Kontakt
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Navigation"
};
</script>

<style lang="scss" scoped>
.navbar-brand {
  font-size: 0.9rem;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    margin-right: 0.5rem;
  }
}
.nav-item {
  font-size: 0.8rem;
  padding: 8px 16px;
  a {
    color: #fff !important;
    &:hover {
      color: #d0d0d0 !important;
    }
  }
}
</style>
