<template>
  <footer>
    <div class="footer-container container-lg container-fluid">
      <div class="row">
        <div class="col-xl-8 col-12">
          <div class="information">
            Jeżeli potrzebujesz strony internetowej, aplikacji mobilnej
            skontaktuj się ze mną
          </div>
          <div class="email my-4 mb-5">
            lukasz@sekutowicz.pl
          </div>
          <div class="copyright">
            Copyright © 2020 Łukasz Sekutowicz | Wszelkie prawa zastrzeżone
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer"
};
</script>

<style lang="scss" scoped>
footer {
  .footer-container {
    background: #181818;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: 70px 150px;
  }
  .information {
    font-size: 25px;
    line-height: 31.37px;
  }
  .email {
    background: linear-gradient(180deg, #e6c100 0%, #ce7c4e 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 50px;
    font-weight: 700;
    line-height: 63px;
    font-family: "Poppins", sans-serif;
  }
  .copyright {
    font-size: 13px;
    line-height: 16px;
    color: #808182;
  }
}
@media (max-width: 1399px) {
  footer {
    .footer-container {
      padding: 60px 80px;
    }
  }
}
@media (max-width: 1100px) {
  footer {
    .footer-container {
      padding: 35px 75px;
    }
  }
}
@media (max-width: 991px) {
  footer {
    padding: 0 15px;
  }
}
@media (max-width: 766px) {
  footer {
    .information {
      font-size: 20px;
      line-height: 31.37px;
    }
    .email {
      font-size: 35px;
    }
  }
}
@media (max-width: 588px) {
  footer {
    .footer-container {
      padding: 20px 30px;
    }
    .email {
      font-size: 25px;
    }
  }
}
</style>
