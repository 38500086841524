<template>
  <vue-aos
    :animation-class="
      `animate__animated ${
        orientation === 'left'
          ? 'animate__slideInRight'
          : 'animate__slideInLeft'
      }`
    "
  >
    <section>
      <div class="container">
        <div class="row">
          <div :class="getImageClasses()">
            <img
              class="img-fluid"
              src="../assets/images/projects/sample-image-1.png"
              alt="Project 1"
            />
          </div>
          <div :class="getTextClasses()">
            <div class="my-5">
              <h2 class="fw-bold font-poppins">
                MobileDesk
              </h2>
              <div class="text-gray font-poppins">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </div>
              <div class="mt-5">
                <button class="btn btn-dark">Odwiedź stronę</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </vue-aos>
</template>

<script>
import VueAos from "vue-aos";
export default {
  name: "Project",
  components: { VueAos },
  props: {
    orientation: {
      type: String,
      required: true
    }
  },
  methods: {
    getTextClasses() {
      let classes = ["col-xl-6", "mx-auto"];
      if (this.orientation === "left") {
        classes = [...classes, "order-xl-1"];
      }
      return classes;
    },
    getImageClasses() {
      let classes = ["col-xl-6", "mx-auto"];
      if (this.orientation === "left") {
        classes = [...classes, "order-xl-2"];
      }
      return classes;
    }
  }
};
</script>

<style scoped></style>
