<template>
  <section id="projects">
    <div class="container">
      <div class="row">
        <div class="col mx-auto">
          <div class="header">
            <div class="title">Moje prace</div>
            <div class="description">
              Codzennie tworzę, aby doskonalić swoje <br />
              umiejętności
            </div>
          </div>
        </div>
      </div>
    </div>
    <swiper class="swiper my-5" :options="swiperOptions">
      <swiper-slide
        class="text-center"
        v-for="(project, index) in projects"
        :key="index"
      >
        <img
          class="img-fluid p-3"
          :src="project"
          :alt="`Project number ${index + 1}`"
        />
      </swiper-slide>
    </swiper>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/swiper.min.css";
export default {
  name: "Projects",
  components: { Swiper, SwiperSlide },
  data() {
    return {
      swiperOptions: {
        slidesPerView: 3,
        grabCursor: true,
        freeMode: true,
        breakpoints: {
          1200: {
            slidesPerView: 3
          },
          768: {
            slidesPerView: 2
          },
          480: {
            slidesPerView: 1
          },
          0: {
            slidesPerView: 1
          }
        }
      },
      projects: [
        require("../assets/images/projects/sample-image-1.png"),
        require("../assets/images/projects/sample-image-2.png"),
        require("../assets/images/projects/sample-image-1.png"),
        require("../assets/images/projects/sample-image-2.png"),
        require("../assets/images/projects/sample-image-1.png"),
        require("../assets/images/projects/sample-image-2.png")
      ]
    };
  }
};
</script>

<style scoped></style>
