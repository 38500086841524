<template>
  <div>
    <Navigation />
    <Cover />

    <Projects />

    <Tools />

    <Project orientation="right" />
    <Project orientation="left" />

    <Footer />
  </div>
</template>

<script>
import Navigation from "./components/Navigation.vue";
import Cover from "./components/Cover.vue";
import Footer from "./components/Footer.vue";
import Tools from "./components/Tools.vue";
import Projects from "./components/Projects.vue";
import Project from "./components/Project.vue";

export default {
  name: "App",
  components: { Project, Projects, Tools, Footer, Cover, Navigation }
};
</script>

<style></style>
