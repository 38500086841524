<template>
  <section id="tools">
    <div class="container-fluid">
      <div class="row">
        <div class="col mx-auto">
          <div class="header">
            <div class="title">Narzędzia</div>
            <div class="description">
              Te programy ułatwiają mi pracę
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center my-5">
        <div
          class="tools-container d-flex justify-content-center align-items-center flex-wrap flex-column flex-sm-row"
        >
          <img
            v-for="(tool, index) in tools"
            :key="index"
            :src="tool.image"
            :alt="`Narzędzie pracy ${index + 1}`"
            class="mx-5 mb-5 mb-lg-0"
            v-tooltip="tool.tooltip"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Tools",
  data() {
    return {
      tools: [
        {
          image: require("../assets/images/tools/figma.png"),
          tooltip: "Figma"
        },
        {
          image: require("../assets/images/tools/ps.png"),
          tooltip: "Photoshop"
        },
        {
          image: require("../assets/images/tools/ai.png"),
          tooltip: "Adobe Illustrator"
        },
        {
          image: require("../assets/images/tools/t1.png"),
          tooltip: "cos"
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
@media (max-width: 710px) {
  .tools-container {
  }
}
</style>
