<template>
  <div>
    <div class="cover">
      <div>
        <div class="hello-header font-poppins">
          Cześć, jestem <strong>Łukasz</strong>
        </div>
        <div
          class="animate__animated animate__bounceIn colored-header font-poppins"
        >
          Junior Graphic <br />
          Designer
        </div>
        <div class="description">
          jestem początkującym <strong>Product Designerem</strong> i
          <strong>Visual Developerem</strong> specjalizuje się w UI/UX Design
          oraz responsywnym projektowaniu stron.
        </div>
        <div class="contact-me">
          <button class="btn btn-light">Napisz do mnie</button>
        </div>
      </div>
    </div>
    <div class="scroll-down">
      <img src="../assets/images/scroll-down.svg" alt="Scroll down" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Cover"
};
</script>

<style lang="scss" scoped>
.cover {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 83px - 70px);
  text-align: center;
  .hello-header {
    font-size: 20px;
  }
  .colored-header {
    font-size: 75px;
    line-height: 93px;
    background: linear-gradient(180deg, #fad961 0%, #ce7c4e 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
  }
  .description {
    margin-top: 26px;
    font-size: 16px;
    max-width: 550px;
    margin-left: auto;
    margin-right: auto;
  }
  .contact-me {
    margin-top: 44px;
  }
}
.scroll-down {
  text-align: center;
  padding: 20px;
  img {
    height: 30px;
  }
}
@media (max-width: 991px) {
  .cover {
    .colored-header {
      font-size: 40px;
      line-height: 50px;
    }
    .description {
      margin-top: 10px;
      font-size: 16px;
    }
  }
}
</style>
